import React, { useState } from "react";
import PropTypes from "prop-types";
import Copyright from "./Copyright";

import { makeStyles } from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";

import Toolbar from "./Toolbar";
import SideDrawer from "./SideDrawer";
import ErrorDisplay from "./ErrorDisplay";

const useStyles = makeStyles((theme) => ({
  Content: {
    display: "flex",
    flex: 1,
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
}));

const Layout = (props) => {
  const classes = useStyles();

  const [sideDrawerOpen, setSideDrawerOpen] = useState(false);
  const sideDrawerClosedHandler = () => {
    setSideDrawerOpen(false);
  };
  const sideDrawerOpenHandler = () => {
    setSideDrawerOpen(true);
  };

  return (
    <React.Fragment>
      <Toolbar
        openSideDrawer={sideDrawerOpenHandler}
        isAuthenticated={props.isAuthenticated}
        onUpdateGlobalSearch={props.onUpdateGlobalSearch}
        globalSearchValue={props.globalSearchValue}
      />
      <ErrorDisplay error={null} />
      <SideDrawer
        sideDrawerOpen={sideDrawerOpen}
        setSideDrawerClosed={sideDrawerClosedHandler}
        setSideDrawerOpen={sideDrawerOpenHandler}
      />
      <main className={classes.Content}>
        <Box alignItems="center" className={classes.fullWidth}>
          {props.children}
        </Box>
      </main>
      <Copyright />
    </React.Fragment>
  );
};

Layout.propTypes = {
  children: PropTypes.element.isRequired,
  onUpdateGlobalSearch: PropTypes.func,
  globalSearchValue: PropTypes.string,
};

export default Layout;
