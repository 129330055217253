export const AUTH_LOGOUT = 'AUTH_LOGOUT';
export const AUTH_SAVE_TOKEN = 'AUTH_SAVE_TOKEN';

export const OC_LOAD_TENANTS_START = 'OC_LOAD_TENANTS_START';
export const OC_LOAD_TENANTS_SUCCESS = 'OC_LOAD_TENANTS_SUCCESS';
export const OC_LOAD_TENANTS_FAIL = 'OC_LOAD_TENANTS_FAIL';

export const OC_ADD_TENANT = 'OC_ADD_TENANT';

export const OC_EDIT_TENANTS_START = 'OC_EDIT_TENANTS_START';
export const OC_EDIT_TENANTS_SUCCESS = 'OC_EDIT_TENANTS_SUCCESS';
export const OC_EDIT_TENANTS_FAIL = 'OC_EDIT_TENANTS_FAIL';

export const OC_DELETE_TENANTS_START = 'OC_DELETE_TENANTS_START';
export const OC_DELETE_TENANTS_SUCCESS = 'OC_DELETE_TENANTS_SUCCESS';
export const OC_DELETE_TENANTS_FAIL = 'OC_DELETE_TENANTS_FAIL';

export const ENQUEUE_SNACKBAR = 'ENQUEUE_SNACKBAR';
export const CLOSE_SNACKBAR = 'CLOSE_SNACKBAR';