import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import "typeface-roboto";
import './App.css';
import {
  Route,
  Redirect,
  withRouter,
  useHistory,
  Switch,
} from "react-router-dom";
import Layout from "./components/General/Layout";
import Login from "./components/Login/Login";
import Code from "./components/Login/Code";
import Tenants from "./containers/Tenants";
import * as actions from './store/actions/index';

const App = (props) => {
  const { onAuthCheckState } = props;
  useEffect(() => {
    onAuthCheckState();
  }, [onAuthCheckState]);

  const [globalSearch, setGlobalSearch] = useState("");

  const token = localStorage.getItem("token");
  // const username = localStorage.getItem("username");
  // const tokenexpires = localStorage.getItem("tokenexpires");
  const isAuthenticated = !!token;

  const history = useHistory();

  const handleLogout = () => {
    localStorage.removeItem("token");
    localStorage.removeItem("username");
    localStorage.removeItem("tokenexpires");
    history.push("/login");
  };

  let page = (
    <Switch>
      <Route path="/code" exact component={Code} />
      <Route path="/login" exact component={Login} />
      <Redirect to="/login" />
    </Switch>
  );

  if (isAuthenticated) {
    page = (
      <Layout onUpdateGlobalSearch={setGlobalSearch} globalSearchValue={globalSearch}>
        <Switch>
          <Route path="/status" exact><Tenants globalSearchValue={globalSearch} /></Route>
          <Route path="/logout" exact
            render={() => {
              handleLogout();
            }}
          />
          <Redirect to={`/status`} />
        </Switch>
      </Layout>
    );
  }

  return page;
}

const mapStateToProps = (state) => {
  return {
    isAuthenticated: state.auth.token !== null,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onAuthCheckState: () => dispatch(actions.authCheckState()),
  };
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App));
