import * as actionTypes from './actionTypes';
import axios from 'axios';
import settings from '../../settings';
import * as toast from './toast';

export const loadTenantsStart = () => {
  return {
    type: actionTypes.OC_LOAD_TENANTS_START,
  };
};

export const loadTenantsSuccess = (tenantData) => {
  return {
    type: actionTypes.OC_LOAD_TENANTS_SUCCESS,
    tenantList: tenantData,
  };
};

export const loadTenantsFail = (error) => {
  return {
    type: actionTypes.OC_LOAD_TENANTS_FAIL,
    error: error,
  };
};

export const loadTenants = () => {
  return async (dispatch) => {
    dispatch(loadTenantsStart());
    const baseServerUrl = settings.baseServerUrl;
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` }, timeout: 1000 * 30 };
    try {
      const mydata = await axios.get(
        `${baseServerUrl}/clients`,
        config
      );
      if (!mydata.data)
        console.log(mydata);
      // console.log(mydata.data);
      // TODO: pre-filter the tenant data into multiple sub-objects

      const tenantList = mydata.data || {};
      // add a property called "filterablevalues" that contains all the other values from the object separated by ||
      const filterableTenants = [...tenantList].map(t => ({ ...t, filterablevalues: Object.values(t).join('||').toLowerCase() }));
      // sort the tenants by id, ascending
      const filteredTenants = [...filterableTenants].sort((a, b) =>
        a.tenantId < b.tenantId ? -1 : 1
      );
      // configure what the groups are that we want to see in the UI
      const tenantGroups = [
        {
          name: 'In Progress and Out of Sync...',
          startExpanded: true,
          searchModeInclude: true,
          searchAlwaysInclude: '',
          searchAlwaysExclude: 'match, no users in, error in, time out',
        },
        {
          name: 'Timeouts in OC',
          startExpanded: true,
          searchModeInclude: true,
          searchAlwaysInclude: 'time out detected in OC',
          searchAlwaysExclude: '',
        },
        {
          name: 'Timeouts in RS',
          startExpanded: false,
          searchModeInclude: true,
          searchAlwaysInclude: 'time out detected in RS',
          searchAlwaysExclude: '',
        },
        {
          name: 'Certificate Errors',
          startExpanded: true,
          searchModeInclude: true,
          searchAlwaysInclude: 'certificate',
          searchAlwaysExclude: '',
        },
        {
          name: '"Not Found" Errors',
          startExpanded: true,
          searchModeInclude: true,
          searchAlwaysInclude: 'ENOTFOUND',
          searchAlwaysExclude: '',
        },
        {
          name: 'Errors',
          startExpanded: true,
          searchModeInclude: true,
          searchAlwaysInclude: 'error in',
          searchAlwaysExclude: 'time out, certificate, ENOTFOUND',
        },
        {
          name: 'No users in OC',
          startExpanded: false,
          searchModeInclude: true,
          searchAlwaysInclude: 'no users in oc',
          searchAlwaysExclude: '',
        },
        {
          name: 'No users in RS',
          startExpanded: false,
          searchModeInclude: true,
          searchAlwaysInclude: 'no users in rs',
          searchAlwaysExclude: '',
        },
        {
          name: 'Matches!',
          startExpanded: false,
          searchModeInclude: true,
          searchAlwaysInclude: 'match',
          searchAlwaysExclude: 'no users in, error in, time out',
        },
      ];
      // modify the groups to augment in the tenant data
      for (const group of tenantGroups) {
        
        if (group.searchAlwaysInclude !== '') {
          group.tenantData = [...filteredTenants].filter(obj => {
            const searchFor = group.searchAlwaysInclude.split(',');
            for (let i = 0; i < searchFor.length; i++) {
              const searchForMe = searchFor[i].trim();
              if (searchForMe.length > 0 && obj.filterablevalues.includes(searchForMe.toLowerCase()))
                return true;
            }
            return false;
          });
        } else {
          group.tenantData = [...filteredTenants];
        };
        if (group.searchAlwaysExclude !== '') {
          group.tenantData = [...group.tenantData].filter(obj => {
            const searchFor = group.searchAlwaysExclude.split(',');
            for (let i = 0; i < searchFor.length; i++) {
              const searchForMe = searchFor[i].trim();
              if (searchForMe.length > 0 && obj.filterablevalues.includes(searchForMe.toLowerCase()))
                return false;
            }
            return true;
          });
        };
        // modify the groups to augment in the tenant count
        group.tenantCount = group.tenantData.length;
      };

      dispatch(loadTenantsSuccess(tenantGroups));
    } catch (e) {
      console.log(e);
      dispatch(loadTenantsFail('unable to get tenants'));
    }
  };
};

export const addTenant = (tenantId) => {
  return async (dispatch) => {
    const baseServerUrl = settings.baseServerUrl;
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    dispatch(toast.enqueueSnackbar({ message: `trying to add tenant ${tenantId}` }));
    try {
      const client = await axios.post(
        `${baseServerUrl}/clients`,
        { tenantid: tenantId },
        config
      );
      //console.log(mydata.data);
      dispatch(loadTenants());
      const name = (client && client.data && client.data.name) || 'unknown?';
      dispatch(toast.enqueueSnackbar({ message: `tenant ${tenantId} (${name}) added successfully`, options: { variant: 'success' } }));
    } catch (e) {
      console.log(e);
      dispatch(toast.enqueueSnackbar({
        message: `tenant ${tenantId} unable to be added: ${(
          e.response.status === 401 ? "Access denied" : e.response.status
        )}`, options: { variant: 'error' }
      }));
    }
  }
};

export const removeTenant = (tenantId) => {
  return async (dispatch) => {
    const baseServerUrl = settings.baseServerUrl;
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    dispatch(toast.enqueueSnackbar({ message: `trying to delete tenant ${tenantId}` }));
    try {
      await axios.delete(
        `${baseServerUrl}/clients/${tenantId}`,
        config
      );
      //console.log(mydata.data);
      dispatch(loadTenants());
      dispatch(toast.enqueueSnackbar({ message: `tenant ${tenantId} deleted successfully`, options: { variant: 'success' } }));
    } catch (e) {
      console.log(e);
      dispatch(toast.enqueueSnackbar({
        message: `tenant ${tenantId} unable to be deleted: ${(
          e.response.status === 401 ? "Access denied" : e.response.status
        )}`, options: { variant: 'error' }
      }));
    }
  }
};

export const refreshTenant = (tenantId, includeOrCacheRefresh = false) => {
  return async (dispatch) => {
    const token = localStorage.getItem('token');
    const config = { headers: { Authorization: `Bearer ${token}` } };
    dispatch(toast.enqueueSnackbar({ message: `trying to ${(includeOrCacheRefresh ? 'refresh' : 'compare')} tenant ${tenantId}` }));
    const baseServerUrl = settings.baseServerUrl;
    try {
      await axios.post(
        `${baseServerUrl}/clients/${tenantId}/refresh${includeOrCacheRefresh ? "/10" : ""}`,
        null,
        config
      );
      //console.log(mydata.data);
      dispatch(loadTenants());
      dispatch(toast.enqueueSnackbar({ message: `tenant ${tenantId} ${(includeOrCacheRefresh ? 'refresh' : 'compare')} initiated successfully`, options: { variant: 'success' } }));
    } catch (e) {
      // console.log('keys', Object.keys(e.response))
      console.log(e);
      dispatch(toast.enqueueSnackbar({
        message: `tenant ${tenantId} unable to be ${(
          includeOrCacheRefresh ? 'refreshed' : 'compared'
        )}: ${(
          e.response.status === 401 ? "Access denied" : e.response.status
        )}`, options: { variant: 'error' }
      }));
    }
  }
};