import React, { useEffect } from "react";
import { withRouter, useHistory } from "react-router-dom";
import queryString from "query-string";
import axios from "axios";
import settings from '../../settings';

const Code = (props) => {
  const history = useHistory();
  const { location } = props;

  useEffect(() => {
    const handleCode = async () => {
      const baseServerUrl = settings.baseServerUrl;
      let success = true;
      // what does this do? what is "location"?
      if (location && location.search) {
        const parms = queryString.parse(location.search);
        if (parms.code) {
          const payload = { code: parms.code };
          try {
            const ret = await axios.post(`${baseServerUrl}/exchangecode`, payload);
            if (ret.status === 200) {
              console.log(ret.data);
              localStorage.setItem("token", ret.data.token);
              localStorage.setItem("username", ret.data.username);
              localStorage.setItem("tokenexpires", ret.data.tokenexpires);
            } else {
              success = false;
              console.log("error when exchanging code: ", ret.status);
            }
          } catch (e) {
            success = false;
            console.log("http post error when exchanging code: ", e);
          }
        } else {
          success = false;
          console.log("no code found in the url");
        }
      }
      if (success) history.push("/");
      else history.push(`/login?messagecode=1`);
    };

    handleCode();
  }, [history, location]);

  return <React.Fragment>finishing login...</React.Fragment>;
};

export default withRouter(Code);
