import React from "react";
import queryString from 'query-string';
import Copyright from "../General/Copyright";
import Logo from "../General/Logo";
import Button from "@material-ui/core/Button";
import axios from "axios";
import settings from '../../settings';

import {
  makeStyles,
  createTheme,
  ThemeProvider,
} from "@material-ui/core/styles";
import Box from "@material-ui/core/Box";
import Typography from "@material-ui/core/Typography";

import ErrorDisplay from "../General/ErrorDisplay";

const loginTheme = createTheme({
  typography: {
    h1: {
      fontSize: "26px",
      lineHeight: "34px",
      fontWeight: 600,
      marginBottom: "10px",
      textAlign: "left",
    },
    h2: {
      fontSize: "26px",
      lineHeight: "34px",
      color: "#fff",
      fontWeight: 600,
      marginBottom: "40px",
    },
    subtitle1: {
      fontSize: "15px",
      lineHeight: "26px",
      textAlign: "left",
      marginBottom: "50px",
    },
    body2: {
      color: "#fff",
      fontSize: "15px",
      lineHeight: "26px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  Content: {
    display: "flex",
    flex: 1,
    textAlign: "center",
  },
  fullWidth: {
    width: "100%",
  },
  sidebar: {
    padding: "4%",
    width: "35%",
    backgroundColor: theme.palette.primary.main,
    borderRight: "1px solid #ddd",
  },
  main: {
    flex: 1,
    backgroundColor: "#f3f3f3",
    padding: "4%",
  },
  container: {
    width: "100%",
    minHeight: "100%",
    display: "flex",
    flexDirection: "row",
    alignItems: "stretch",
  },
  logo: {
    position: "absolute",
    top: "50px",
    left: "50px",
  },
  leftContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    paddingBottom: "20%",
    height: "100%",
  },
  mainContent: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    justifyContent: "center",
    height: "100%",
    width: "80%",
    margin: "auto",
    alignItems: "flex-start",
    paddingBottom: "12%",
  },
}));

const Login = (props) => {
  const classes = useStyles();

  const baseClientUrl = settings.baseClientUrl;
  const baseServerUrl = settings.baseServerUrl;

  const handleLogin = async (event) => {
    try {
      const res = await axios.get(`${baseServerUrl}/oauth2/client`, { timeout: 1000 * 30 });
      const clientId = res.data;
      const redirectUri = encodeURIComponent(`${baseClientUrl}/code`);
      const url = `https://launchpad.classlink.com/oauth2/v2/auth?scope=profile&redirect_uri=${redirectUri}&client_id=${clientId}&response_type=code`;
      window.location.href = url;
    } catch (e) {
      console.log(e.data);
    }
  };

  const getErrorFromUrl = () => {
    if (props.location.search) {
      const parsed = queryString.parse(props.location.search);
      const messagecode = parsed.messagecode;
      if (messagecode === "1")
        return "Contact Jamie to gain access";
    }
    return "";
  };

  const sidebar = (
    <React.Fragment>
      <div className={classes.logo}>
        <Logo width="75px" />
      </div>
      <div className={classes.leftContent}>
        <ThemeProvider theme={loginTheme}>
          <Typography variant="h2">Validate ORCache</Typography>
          <Typography variant="body2">
            Check specified tenants daily for parity between RosterServer and the ClassLink Cloud
          </Typography>
        </ThemeProvider>
      </div>
    </React.Fragment>
  );

  const login = (
    <React.Fragment>
      <div className={classes.mainContent}>
        <ThemeProvider theme={loginTheme}>
          <Typography variant="h1">Sign in</Typography>
        </ThemeProvider>
        <Button
          fullWidth={false}
          variant="contained"
          color="primary"
          onClick={handleLogin}
        >
          Log in with launchpad
        </Button>
      </div>
    </React.Fragment>
  );

  let page = (
    <React.Fragment>
      <main className={classes.Content}>
        <Box alignItems="center" className={classes.fullWidth}>
          <Box className={classes.container}>
            <Box className={classes.sidebar}>{sidebar}</Box>
            <Box className={classes.main}>
              <ErrorDisplay error={getErrorFromUrl()} />
              {login}
            </Box>
          </Box>
        </Box>
      </main>
      <Copyright />
    </React.Fragment>
  );

  return page;
};

export default Login;
