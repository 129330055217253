import * as actionTypes from './actionTypes';
import Button from '@material-ui/core/Button';

export const enqueueSnackbar = (notification) => {
  const key = notification.options && notification.options.key;
  const variant = notification.options && notification.options.variant;
  const action = notification.options && notification.options.action;
  const defaultaction = (key) => (<Button onClick={() => closeSnackbar(key)}>dismiss me</Button>);
  return {
    type: actionTypes.ENQUEUE_SNACKBAR,
    notification: {
      ...notification,
      key: key || new Date().getTime() + Math.random(),
      variant: variant || 'info',
      action: action || defaultaction,
    },
  };
};

export const closeSnackbar = (key) => ({
    type: actionTypes.CLOSE_SNACKBAR,
    dismissAll: !key, // dismiss all if no key has been defined
    key,
});

/*
{
    message: 'Failed fetching data.',
    options: {
        key: new Date().getTime() + Math.random(),
        variant: 'warning',
        action: key => (
            <Button onClick={() => closeSnackbar(key)}>dismiss me</Button>
        ),
    },
}
*/