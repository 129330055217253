import React, { useState } from 'react';
import PropTypes from 'prop-types';

// import { useTheme, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import TextField from '@material-ui/core/TextField';

const AddTenantDialog = (props) => {
  // const theme = useTheme();
  const [tenantId, setTenantId] = useState();

  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Add a Tenant by ID'}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          id="tenantid"
          label="Tenant ID"
          type="text"
          value={tenantId}
          onChange={(event) => { setTenantId(event.target.value); }}
          onKeyDown={(event) => { if (event.key === 'Enter') { props.onAddTenant(tenantId); setTenantId(""); } }}
          fullWidth
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { props.onCancel(); setTenantId("") }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onAddTenant(tenantId); setTenantId(""); }} color="primary">
          Add Tenant
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AddTenantDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onAddTenant: PropTypes.func,
};

export default AddTenantDialog;
