import React, { useEffect, useState, useRef } from "react";
import { connect } from 'react-redux';
import { makeStyles, createTheme, ThemeProvider } from '@material-ui/core/styles';
import CircularProgress from "@material-ui/core/CircularProgress";
import LinearProgress from '@material-ui/core/LinearProgress';
import TenantTable from '../components/Tenants/TenantTable';
import AddTenantDialog from '../components/General/AddTenantDialog';
import DeleteTenantDialog from "../components/General/DeleteTenantDialog";
import Notifier from '../components/General/Notifier';
import * as actions from '../store/actions';

const tenantTheme = createTheme({
  typography: {
    h2: {
      fontSize: "26px",
      // lineHeight: "34px",
      // color: "#fff",
      // fontWeight: 600,
      // marginBottom: "40px",
    },
  },
});

const useStyles = makeStyles((theme) => ({
  errorPaper: {
    width: '100%',
    borderRadius: 0,
    padding: '2rem',
  },
  linearProgress: {
    marginBottom: '-4px',
    // float: true
  }
}));

const Tenants = (props) => {
  const { onLoadTenants, tenantList, loadingTenants, onAddTenant, onRemoveTenant, onRefreshTenant } = props;
  const [isAddModalOpen, setIsAddModalOpen] = useState(false); 
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [tenantToDelete, setTenantToDelete] = useState(null);

  const classes = useStyles();
  const searchFor = props.globalSearchValue || "";

  // useEffect(() => {
  //   onLoadTenants();
  // }, [onLoadTenants]);

  const useInterval = (callback, delay) => {
    const savedCallback = useRef();

    // Remember the latest callback.
    useEffect(() => {
      savedCallback.current = callback;
      callback();
    }, [callback]);

    // Set up the interval.
    useEffect(() => {
      const tick = () => {
        savedCallback.current();
      };
      if (delay !== null) {
        let id = setInterval(tick, delay);
        return () => clearInterval(id);
      }
    }, [delay]);
  };

  useInterval(onLoadTenants, 15000); // 15 seconds - TODO: make this configurable

  let page = <CircularProgress />;
  if (!!tenantList) {
    page = (
      <React.Fragment>
        <ThemeProvider theme={tenantTheme}>
          <Notifier />
          {(loadingTenants
            ? <div className={classes.linearProgress}><LinearProgress /></div>
            : null)
          }
          {tenantList.map((tenantGroup) => (
            <TenantTable
              key={tenantGroup.name}
              tenantList={tenantGroup}
              openAddDialog={() => { setIsAddModalOpen(true); }}
              openDeleteDialog={(tenantId) => { setTenantToDelete(tenantId); setIsDeleteModalOpen(true); }}
              onRefreshTenant={(tenantId) => { onRefreshTenant(tenantId, true); }}
              onRecompareTenant={(tenantId) => { onRefreshTenant(tenantId, false); }}
              searchString={searchFor}
              searchModeInclude={true}
            />
          ))}
          <AddTenantDialog
            isOpen={isAddModalOpen}
            onCancel={() => { setIsAddModalOpen(false) }}
            onAddTenant={(tenantId) => {
              setIsAddModalOpen(false);
              onAddTenant(tenantId);
            }}
          />
          <DeleteTenantDialog
            isOpen={isDeleteModalOpen}
            onCancel={() => setIsDeleteModalOpen(false)}
            tenantToDelete={tenantToDelete}
            onDeleteTenant={() => { onRemoveTenant(tenantToDelete); setIsDeleteModalOpen(false) }}
          />
        </ThemeProvider>
      </React.Fragment>
    );
  }
  return page;
};

const mapStateToProps = (state) => {
  return {
    loadingTenants: state.tenants.loadingTenants,
    tenantList: state.tenants.tenantList,
    addTenantDialogOpen: state.tenants.addTenantDialogOpen,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    onLoadTenants: () => dispatch(actions.loadTenants()),
    onAddTenant: (tenantId) => dispatch(actions.addTenant(tenantId)),
    onRemoveTenant: (tenantId) => dispatch(actions.removeTenant(tenantId)),
    onRefreshTenant: (tenantId, doOrCacheToo) => dispatch(actions.refreshTenant(tenantId, doOrCacheToo)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Tenants);
