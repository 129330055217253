

const baseClientUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3000"
    : "https://orcache.oboesax.com";
const baseServerUrl =
  process.env.NODE_ENV === "development"
    ? "http://localhost:3090"
    : "/api";

const settings = {
  baseClientUrl: baseClientUrl,
  baseServerUrl: baseServerUrl,
};

export default settings;