import * as actionTypes from '../actions/actionTypes';

const initialState = {
  tenantList: [],
  loadingTenants: false,
  loadingActions: false,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.OC_LOAD_TENANTS_START:
      return { ...state, loadingTenants: true, error: null };
    case actionTypes.OC_LOAD_TENANTS_SUCCESS:
      return { ...state, tenantList: [...action.tenantList], loadingTenants: false, error: null };
    case actionTypes.OC_LOAD_TENANTS_FAIL:
      return { ...state, tenantList: [], loadingTenants: false, error: action.error };
    case actionTypes.OC_ADD_TENANT:
      return { ...state};
    case actionTypes.OC_EDIT_TENANTS_START:
    case actionTypes.OC_EDIT_TENANTS_SUCCESS:
    case actionTypes.OC_EDIT_TENANTS_FAIL:
    case actionTypes.OC_DELETE_TENANTS_START:
    case actionTypes.OC_DELETE_TENANTS_SUCCESS:
    case actionTypes.OC_DELETE_TENANTS_FAIL:
    default:
      return state;
  }
};

export default reducer;
