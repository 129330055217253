import React from 'react';
import PropTypes from 'prop-types';

// import { useTheme, createTheme } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
// import ThemeProvider from '@material-ui/styles/ThemeProvider';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
// import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Typography from "@material-ui/core/Typography";

const DeleteTenantDialog = (props) => {
  const tenantId = props.tenantToDelete;
  return (
    <Dialog
      open={props.isOpen}
      onClose={props.onCancel}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{'Delete Tenant?'}</DialogTitle>
      <DialogContent>
        <Typography>
          Are you sure you want to delete tenant {tenantId}?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => { props.onCancel() }} color="primary">
          Cancel
        </Button>
        <Button onClick={() => { props.onDeleteTenant(tenantId) }} color="primary">
          Delete Tenant {tenantId}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

DeleteTenantDialog.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  tenantToDelete: PropTypes.string,
  onDeleteTenant: PropTypes.func,
};

export default DeleteTenantDialog;
