import * as actionTypes from '../actions/actionTypes';

const initialState = {
  notifications: [],
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.ENQUEUE_SNACKBAR:
      return { ...state, notifications: [...state.notifications, { key: action.key, ...action.notification, }] };
    case actionTypes.CLOSE_SNACKBAR:
      return { ...state, 
        notifications: state.notifications.map(notification => (
          (action.dismissAll || notification.key === action.key)
          ? { ...notification, dismissed: true }
          : { ...notification })),
      };
    default:
      return state;
  }
};

export default reducer;
