import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Tooltip from "@material-ui/core/Tooltip";
import Paper from '@material-ui/core/Paper';
import moment from 'moment';
import cronstrue from 'cronstrue';
import Typography from "@material-ui/core/Typography";

// import EditIcon from '@material-ui/icons/Edit';
import RefreshIcon from '@material-ui/icons/Refresh';
import AutorenewIcon from '@material-ui/icons/Autorenew';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import DeleteIcon from '@material-ui/icons/Delete';
import AddIcon from '@material-ui/icons/Add';
import RemoveIcon from '@material-ui/icons/Remove';

const useStyles = makeStyles({
  table: { minWidth: 550 },
  paper: { maxWidth: 1400, marginTop: 5, marginBottom: 20, marginLeft: 'auto', marginRight: 'auto' },
  actioncolumn: { minWidth: 120, textAlign: 'center', cursor: 'pointer' },
});

const buildTooltipCronString = (cronstring) => {
  if (cronstring.includes('|')) {
    const minutesLeft = cronstring.split('|')[0];
    if (minutesLeft <= 0) return 'ASAP';
    return `about ${minutesLeft} cycle${(minutesLeft === 1 ? "" : "s")}`;
  }
  const ret = cronstrue.toString(cronstring, { throwExceptionOnParseError: false });
  if (!ret)
    return "";
  return ret;
};

// const boolToPrettyString = (b) => {
//   if (b === 1 || b === '1' || b === true || b === 'True') {
//     return 'True';
//   }
//   return 'False';
// }

const TenantTable = (props) => {
  const [tableExpanded, setTableExpanded] = useState(props.tenantList.startExpanded);
  const classes = useStyles();
  const searchString = props.searchString.toLowerCase() || '';
  const tenantGroup = props.tenantList;
  const filterableTenants = [...tenantGroup.tenantData].map(t => ({ ...t, filterablevalues: Object.values(t).join('||').toLowerCase() }));
  const finalTenants = [...filterableTenants].filter(obj => (props.searchModeInclude
    ? obj.filterablevalues.includes(searchString)
    : !obj.filterablevalues.includes(searchString)
  ));
  const searchedCount = finalTenants.length;
  const searchCountDisplay = searchedCount === tenantGroup.tenantCount ?
    `${tenantGroup.tenantCount}` :
    `${searchedCount} / ${tenantGroup.tenantCount}`;
  function refreshAll(tenants) {
    if (!tenants || tenants.length === 0) return;
    if (!tenants[0].schedule.includes('|')) {
      props.onRefreshTenant(tenants[0].tenantid);
    }
    if (tenants.length > 1) {
      setTimeout(() => { refreshAll(tenants.slice(1)); }, 3000);
    }
  };
  function recompareAll(tenants) {
    if (!tenants || tenants.length === 0) return;
    if (!tenants[0].schedule.includes('|')) {
      props.onRecompareTenant(tenants[0].tenantid);
    }
    if (tenants.length > 1) {
      setTimeout(() => { recompareAll(tenants.slice(1)); }, 1000);
    }
  };
//(tenantid integer primary key, name text, url text, key text, secret text, schedule text, currentstatus text, lastrun text, isactive integer);
  return (
    <React.Fragment>
      <Typography variant="h2">
      {(tableExpanded ?
          <RemoveIcon
            onClick={() => { setTableExpanded(!tableExpanded); }}
          /> :
          <AddIcon
            onClick={() => { setTableExpanded(!tableExpanded); }}
          />)}
        {tenantGroup.name} ({searchCountDisplay})
      </Typography>
      {(tableExpanded ?
        <TableContainer component={Paper} className={classes.paper}>
          <Table className={classes.table} aria-label="simple table">
            <TableHead>
              <TableRow>
                <TableCell>Tenant ID</TableCell>
                <TableCell>Tenant Name</TableCell>
                <TableCell>DB Cluster</TableCell>
                <TableCell>Last Status</TableCell>
                <TableCell>Last Check</TableCell>
                <TableCell>Next Check</TableCell>
                {/* <TableCell>Active</TableCell> */}
                <TableCell className={classes.actioncolumn}>
                  {(props.onRecompareTenant ?
                    <Tooltip title={"Compare again"}>
                      <RefreshIcon onClick={() => { recompareAll(finalTenants) }} />
                    </Tooltip> : ""
                  )}
                  {(props.onRefreshTenant ?
                    <Tooltip title={"Refresh ORCache then compare again"}>
                      <AutorenewIcon onClick={() => { refreshAll(finalTenants) }} />
                    </Tooltip> : ""
                  )}
                  <Tooltip title={"Add a new tenant"}>
                    <AddCircleOutlineIcon onClick={props.openAddDialog} />
                  </Tooltip>
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {finalTenants.map((tenant) => (
                <TableRow key={tenant.tenantid}>
                  <TableCell component="th" scope="row">
                    {tenant.tenantid}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={tenant.url || ""}>
                      <span>
                        {tenant.name}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    {tenant.dbcluster}
                  </TableCell>
                  <TableCell>
                    {tenant.currentstatus}
                  </TableCell>
                  <TableCell>
                    <Tooltip title={moment(tenant.lastrun).toISOString() || "unknown"}>
                      <span>
                        {(tenant.lastrun ? moment(tenant.lastrun).fromNow() : "")}
                      </span>
                    </Tooltip>
                  </TableCell>
                  <TableCell>
                    <Tooltip title={tenant.schedule || "unknown"}>
                      <span>
                        {buildTooltipCronString(tenant.schedule)}
                      </span>
                    </Tooltip>
                  </TableCell>
                  {/* <TableCell>
                {boolToPrettyString(tenant.isactive)}
              </TableCell> */}
                  <TableCell className={classes.actioncolumn}>
                    {/* <EditIcon/>*/}
                    {(props.onRecompareTenant ?
                      <Tooltip title={"Compare again"}>
                        <RefreshIcon onClick={() => { props.onRecompareTenant(tenant.tenantid) }} />
                      </Tooltip> : ""
                    )}
                    {(props.onRefreshTenant ?
                      <Tooltip title={"Refresh ORCache then compare again"}>
                        <AutorenewIcon onClick={() => { props.onRefreshTenant(tenant.tenantid) }} />
                      </Tooltip> : ""
                    )}
                    <Tooltip title={"Remove tenant from comparison"}>
                      <DeleteIcon onClick={() => { props.openDeleteDialog(tenant.tenantid) }} />
                    </Tooltip>
                  </TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer> : null
      )}
    </React.Fragment>
  );
};

TenantTable.propTypes = {
  releaseList: PropTypes.array,
  openAddDialog: PropTypes.func,
  openDeleteDialog: PropTypes.func,
  searchString: PropTypes.string,
  // searchModeInclude: PropTypes.bool,
  // searchAlwaysInclude: PropTypes.string,
  // searchAlwaysExclude: PropTypes.string,
};

export default TenantTable;
